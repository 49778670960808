@import url(https://rsms.me/inter/inter.css);
/*@import url('https://cdnjs.cloudflare.com/ajax/libs/modern-normalize/0.5.0/modern-normalize.min.css');*/
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
}

body {
    font-family: 'Inter', -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
}
